.profile-img-wrapper {
  margin-top: 16px;
  margin-bottom: 32px;
}


.send-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > input {
    margin-right: 32px;
  }
}

.info-wrapper {
  margin-top: -20px;
  margin-bottom: 24px;
  > span {
    margin-right: 8px;
    > svg {
      path {
        fill: #007AFF;
      }
    }
  }
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
