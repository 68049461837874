.ant-table-row {
  height: 91px;
}

td.ant-table-cell.ant-table-cell-row-hover {
  background: #EAFFDC !important;
}

.actions-wrapper {
  width: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .remove {
    path {
      fill: #E2302D;
    }
  }
}
