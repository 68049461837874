.action-btn-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.custom-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  height: 40px;
}

.secondary {
  color: #007AFF;
  border-color: #007AFF;
  svg {
    margin-right: 8px;
    path {
      fill: #007AFF;
    }
  }
}
