.admin-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #F8F7F8;
  border-radius: 2px;
  width: 100%;

  .admin-item {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 16px 0;
    gap: 10px;

    .admin-email {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      padding-left: 16px;
    }
  }

  .admin-del-btn {
    margin-right: 16px;
    path {
      fill: #E2302D;
    }
  }
}
.input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
  .admin-input {
    display: flex;
    flex: 1 0 50%;
    margin-right: 16px;
  }

  .admin-add-btn {
    display: flex;
  }
}




