.header-wrapper {
  padding: 22px 30px;
  background-color: #fff;
  max-height: 100px;

  .input {
    height: 54px;
    max-width: 50%;
  }
}
