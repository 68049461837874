.login-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100vw;
  height: 100vh;

  .logo-wrapper {
    margin: 42px 0 0 0;
    max-width: 470px;
    width: 100%;
    height: 32px;
  }

  .content {
    display: flex;
    flex: 0 0 50%;
    height: 100vh;
    flex-flow: column nowrap;
    background: #FAFAFA;
    align-items: center;

    &.background {
      background-image: url('../../assets/login-background.png');
      background-size: cover;
    }
  }

  .login-box-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 380px;
    max-width: 470px;
    margin: 30% 130px 0 135px;
    padding: 42px 32px;
    gap: 10px;

    background: #FFFFFF;
    box-shadow: 0 0 80px rgba(171, 171, 171, 0.1);
    border-radius: 12px;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }
  }

  .login-btn {
    border-radius: 0;
    height: 40px;
    margin-top: 32px;
    width: 100%;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.5px;
      color: #FFFFFF;
    }

  }

  .custom-input {
    height: 40px;

    .ant-input {
      font-size: 14px !important;
      line-height: 1.5714285714285714;
      list-style: none;
      font-family: Open Sans, sans-serif;
    }
  }

}

