.pagination-wrapper {
  margin: 24px auto;
}

.pagination-previous {
  margin-right: 82px;
}

.pagination-next {
  margin-left: 82px;
}
