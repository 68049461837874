// does not support styling properly
// https://github.com/ant-design/ant-design/issues/9719
section.ant-layout.ant-layout-has-sider {
  aside.ant-layout-sider.ant-layout-sider-light {
    width: 305px !important;
    min-width: 305px !important;
    max-width: 305px !important;
    padding: 32px;
  }
}
ul.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

li.ant-menu-item {
  color: #979797;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  border-radius: 0;
  margin-bottom: 24px !important;

  :hover {
    background-color: unset !important;
    color: #5FD204;
  }

  &.ant-menu-item-active {
    background-color: unset !important;
    color: #5FD204;
    svg {
      path {
        fill: #5FD204;
      }
    }
  }

  &.ant-menu-item-selected {
    color: #5FD204;
    border-radius: 0;
    box-shadow: -4px 0px 0px #5FD204;
    background-color: unset;
    svg {
      path {
        fill: #5FD204;
      }
    }
  }
}

.logo-wrapper {
  margin: 0 0 32px 32px;
  width: 100px;
  height: 32px;
}

.logout-wrapper {
  position: fixed;
  bottom: 0;
  padding: 32px 0;
  border-top: 1px solid #F1F1F1;

  li.ant-menu-item {
    color: #231F20;
  }
}

.page-wrapper {
  width: 100%;
  display: block;
  max-height: 100vh;
  background: #F7F7F7;

  .page-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    padding: 40px;
  }
  .page-content {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 30px;
    align-items: center;
    background: #F7F7F7;
  }
}


.row-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.modal-section-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #007AFF;
  margin: 24px 0;
}

.modal-content {
  border-top: 1px solid #E0E0E0;
  width: 100%;
  margin: 0;
  padding: 0;

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
    >label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #757575;
    }
  }


  .ant-select-selector {
    width: 100%;
    min-width: 160px;
  }

  .row-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 10px;
  }

  .custom-input {
    min-width: 160px;
  }
}

.ant-modal {
  max-width: 714px;
  min-width: 512px;
  width: 100% !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #18A000;
  border-color: #18A000;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  background-color: #18A000 !important;
  border-color: #18A000 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #18A000;
  border-color: #18A000;
}

img.table-img {
  max-height: 64px;
  max-width: 64px;
}
